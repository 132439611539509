/*****************************************************************************
 * UmanIT - Remove banner
 *****************************************************************************/

const btnsClose = document.querySelectorAll("[data-trigger='close-banner']"),
      header = document.querySelector("[data-layout='header']");

btnsClose.forEach(function(btnClose) {
  const banner =  btnClose.closest("[data-target='banner']");
  btnClose.addEventListener("click", function(e) {
    e.preventDefault();
    banner.remove();
    header.removeAttribute("style");

    var date = new Date();
    date.setTime(date.getTime()+(1*24*60*60*1000));
    document.cookie = "cookies-banner=true; expires="+date.toGMTString();
  });
});
